<template>
  <v-alert text type="info">
    <span v-html="localize(value.title)"></span>
  </v-alert>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  }
};
</script>

<style></style>
